import React from "react";
import { Routes, Route } from "react-router-dom";
import MapView from "./Map";

const App = () => {

  return (

    <Routes>

      <Route path="/:id" element={<MapView />} />
    </Routes>
  );
};

export default App;