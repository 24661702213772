import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIconB from './Assets/marker-icon-b.png';
import markerIconA from './Assets/marker-icon-a.png';

const MapView = () => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (!mapRef.current) {
      const map = L.map('map').setView([1.2921, 36.8219], 10);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?lang=en', {
        attribution: '&copy; Cinab'
      }).addTo(map);

      const customIconA = L.icon({
        iconUrl: markerIconA,
        iconSize: [32, 32],
        iconAnchor: [16, 32]
      });

      const customIconB = L.icon({
        iconUrl: markerIconB,
        iconSize: [32, 32],
        iconAnchor: [16, 32]
      });

      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          const userLocation = new L.LatLng(latitude, longitude);

          // Adjust coordinates for points A and B to add margin
          const marginLat = 0.1; // Adjust this value as needed
          const marginLng = 0.1; // Adjust this value as needed

          const markerB = L.marker(userLocation, { icon: customIconA }).addTo(map);
          markerB.bindTooltip('Your Product will be here ', { direction: 'start' });

          const pointB = new L.LatLng(-1.2921 - marginLat, 36.8219 + marginLng);
          const markerA = L.marker(pointB, { icon: customIconB }).addTo(map);
          markerA.bindTooltip('On transit to Cinab warehouse', { direction: 'end' });

          const polyline = L.polyline([userLocation, pointB], {
            color: 'blue',
            weight: 5,
            opacity: 0.7,
            dashArray: '10 5'
          }).addTo(map);

          // Adjust bounds to include margin around points A and B
          const bounds = L.latLngBounds([userLocation, pointB]).pad(0.1); // Adjust padding as needed
          map.fitBounds(bounds);
        },
        error => {
          console.error('Error getting user location:', error);
        }
      );

      mapRef.current = map;
    }
  }, []);

  return <div id="map" style={{ height: '100vh', width: '100vw' }}></div>;
};

export default MapView;
